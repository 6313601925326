import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/SEO";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  gap: 3rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  text-align: center;
  width: 80%;
  > h2 {
    font-size: 1.65rem;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  max-width: 400px;
  box-shadow: 1px 1px 1rem var(--grey);
`;

const ItemGrid = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`;
const ItemContainer = styled.div`
  width: 100%;
  > h3 {
    text-decoration: underline;
  }
`;
const Item = styled.li`
  color: var(--grey);
  text-align: center;
`;

const Bio = styled.p`
  color: var(--grey);
`;

const Divider = styled.div`
  background-color: var(--grey);
  height: 1px;
  width: 100%;
`;

export default function Person({ data }) {
  const person = data.person;
  return (
    <>
      <Seo title={person.name} />
      <Layout>
        <Container>
          <StyledGatsbyImage
            image={person.image.asset.gatsbyImageData}
            alt={person.name}
          />
          <div>
            <h2>
              <span>{person.name}</span>
            </h2>
            <p>
              <span>
                <i>{person.jobTitle}</i>
              </span>
            </p>
            <Bio>
              <span>{person.bio}</span>
            </Bio>
          </div>
          <Divider />
          {person.degrees.length > 0 && (
            <ItemContainer>
              <h3>Degrees and Certifications</h3>
              <ItemGrid>
                {person.degrees.map((degree, i) => (
                  <Item key={i}>{degree}</Item>
                ))}
              </ItemGrid>
            </ItemContainer>
          )}
          {person.specialities.length > 0 && (
            <ItemContainer>
              <h3>Specialities and Focus</h3>
              <ItemGrid>
                {person.specialities.map((speciality, i) => (
                  <Item key={i}>{speciality}</Item>
                ))}
              </ItemGrid>
            </ItemContainer>
          )}
        </Container>
      </Layout>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    person: sanityPerson(slug: { current: { eq: $slug } }) {
      id
      name
      jobTitle
      bio
      degrees
      specialities
      image {
        asset {
          # todo
          gatsbyImageData(aspectRatio: 1, placeholder: BLURRED)
        }
      }
    }
  }
`;
